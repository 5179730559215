import React, {Component} from 'react';
import getReports from './requests/reports'
import Report from './components/Report';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import 'typeface-roboto';
import './App.css'; 

class App extends Component {
  constructor(){
    super();
    this.state = {reports:[]}
    getReports().then(reports => {
      this.setState({
        reports:reports.inspection_reports
      });
    });

    setInterval(async () => {
      const reports = await getReports();      
      this.setState({reports:reports.inspection_reports});
    }, 50000);
  }
  
  render() {
    const {reports} = this.state;
    return (
    <Box>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
      {reports && reports.map((report) => (<Report count={reports.length} report={report.inspection_report} key={report.inspection_report.id}></Report>))}
      </Grid>
    </Box>
    );
  }
}


export default App;
