import React from 'react';
import styled from 'styled-components';
import moment from 'moment'; 
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

function Report({report, className, count}){
  const {time, vehicle, defects, status, driver} = report;
  const {first_name, last_name} = report.driver;
  return (
    <Grid className={className} item xs={count>13?6:12} >
      <Card elevation={3}>
        <Box>
          <Box display='flex' justifyContent="space-between">
            <Box className='vehicle'>{vehicle.number} - {vehicle.year} {vehicle.make} {vehicle.model} - VIN# {vehicle.vin}</Box>
            <Box justifyContent={'center'} style={{whiteSpace:'nowrap'}}>Driver: {driver.first_name} {driver.last_name}</Box>
            <Box>
              <div className='date'>{moment(time).format('MMM D, h:mma')}<br />Status: {status}</div>
            </Box>
          </Box>
          <Box className='defects'>
              {defects.map(({defect}) => (
                <div className='defect' key={defect.id}>{defect.type} defect: {defect.category} / {defect.notes}</div>  
              ))}
          </Box>
        </Box>
        
      </Card>
    </Grid>
  )

}

const Card =styled(Paper)`
  
  position:relative;
  
  padding: 10px;
  height:100%;

  .date {
    text-align: right;
    white-space: nowrap;
    color: gray;
    font-size:.8em;
  }
  .vehicle {
    white-space: nowrap;
  }
  .defects {
    font-size: .9em;
    color: red;
    width: 100%;
  }

`;


export default styled(Report)`
  padding:5px;
  font-family: 'Roboto';
  font-size:${({count}) => 2 - count/26}rem;
  height: ${({count}) => count>13?(2*100/count):(100/count)}vh;
  min-height: ${({count}) => count>13?100:120}px;
`;